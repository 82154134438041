@charset "utf-8";
/* CSS Document */
@media (max-width: 768px) {
  button.w-50,
  button.w-75 {
    width: 100% !important;
  }
  .list-group-item.active strong {
    left: 0px !important;
  }
  .dot_slider.list-group-horizontal .list-group-item {
    padding: 0.55rem 0rem !important;
    margin-right: 0.1rem !important;
  }

  .homeBanner {
    margin-top: 0px !important;
  }
}
@media (max-width: 1200px) {
  img {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .barSlider .list-group-item:first-child {
    margin-left: -7px !important;
  }
  .barSlider .list-group-item:last-child {
    margin-right: -8px !important;
  }
  .homePageIntro {
    height: 654px;
  }
  .introSection button,
  .contentSection button {
    width: 360px !important;
  }
  .theme-width {
    max-width: 960px;
  }
}
body {
  letter-spacing: -0.1px;
}
.text-primary {
  color: #6442ef;
}
.btn-outline-primary {
  border-color: #6442ef;
  color: #6442ef;
}
.btn-primary,
.progress-bar {
  background-color: #6442ef;
  border-color: #6442ef;
}
.fw-bold-m {
  font-family: "euclid_circular_bmedium";
}
.main-wrapper {
  margin: 40px;
}

.spinner-border {
  width: 7rem;
  height: 7rem;
  border-width: 0.5em;
}
.icon.badge {
  width: 40px;
  height: 40px;
  display: flex;
  text-decoration: none;
}
.flex1 {
  flex: 1;
}
.horizentalBar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  border-radius: 10px;
}

.align-self-end {
  align-self: flex-end;
}
.list-group-item {
  position: relative;
}
.list-group-item.active {
  background-color: rgba(0, 0, 0, 0);
}
.list-group-item.active .youAcitve {
  position: absolute;
  left: 50%;
  font-size: 14px;
  top: 165%;
  color: #6442ef;
  transform: translate(-50%, 0);
}
.list-group-item.active .activeYou {
  position: absolute;
  left: 50%;
  font-size: 14px;
  top: 120%;
  color: #6442ef;
  transform: translate(-50%, 0);
}
.list-group-item.active .horizentalBar {
  background-color: #fd8f55 !important;
}

.dot_slider {
  margin-left: -10px;
}
.dot_slider .list-group-item.active .horizentalBar {
  background-color: #6442ef !important;
}
.dot_slider.list-group-horizontal .list-group-item {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0.55rem 0.6rem;
  width: 28px;
  height: 28px;
  border-radius: 10px;
  flex: 1;
  display: flex;
}

.dot_slider.list-group-horizontal .list-group-item.acticeCircle::before {
  border: 2px solid rgb(0, 0, 0, 1);
  content: "";
  overflow: hidden;
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.dot_slider.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
}
.dot_slider.list-group-horizontal .list-group-item.acticeCircle {
  border: 1px solid rgba(0, 0, 0, 0);
}
.dot_slider.list-group-horizontal .list-group-item.active {
  border: 1px solid rgba(0, 0, 0, 0);
}
.spinner-border {
  border-width: 0.37em;
}
button {
  border-radius: 16px !important;
  height: 48px;
  box-shadow: 0 1px 16px rgba(100, 66, 239, 0.3) !important;
}

button.btn-quiz {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08) !important;
}

button.btn-cta {
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  background-color: #6442ef;
  color: #ffffff;
}

button.btn-cta:hover {
  background-color: #6442ef;
}

button.btn-quiz:hover {
  background-color: transparent !important;
}

button.btn-lg {
  border-radius: 32px !important;
}
button.bg-transparent {
  box-shadow: none !important;
}
button.btn-outline-primary {
  box-shadow: none !important;
}

.btn-primary:hover {
  background-color: #6442ef !important;
  border-color: #6442ef !important;
}

.btn-outline-primary:hover {
  color: #6442ef !important;
  background-color: #fff !important;
  border-color: #6442ef !important;
}

@media (hover: none) {
  button.btn-primary:active {
    background-color: #c1b3f9 !important;
    border-color: #c1b3f9 !important;
  }

  .btn-outline-primary:active {
    color: #fff !important;
    background-color: #c1b3f9 !important;
    border-color: #c1b3f9 !important;
  }
}

.introSection button,
.contentSection button {
  border-radius: 32px !important;
}
.introSection button,
.contentSection button {
  height: 84px !important;
}
.customTooltip {
  display: none;
  width: 34px;
  height: 41px;
  border-radius: 10px 0 10px 0;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  background: url(../img/icon_tooltip.png) 0 0 / 100% no-repeat;
}

.customTooltip strong {
  display: block;
  font-size: 18px;
  margin-top: 4px;
  color: #ffffff;
}

.list-group-item:hover .customTooltip {
  display: block !important;
}

.customTooltipShow {
  display: none;
  width: 34px;
  height: 41px;
  border-radius: 10px 0 10px 0;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  background: url(../img/icon_tooltip.png) 0 0 / 100% no-repeat;
}

.customTooltipShow strong {
  display: block;
  font-size: 18px;
  margin-top: 4px;
  color: #ffffff;
}

.list-group-item .customTooltipShow {
  display: block !important;
}

.homeBanner {
  margin-top: -70px;
}
.textopacity {
  color: rgba(33, 33, 33, 0.6) !important;
  letter-spacing: -0.1px;
  line-height: 30px;
}

.textopacity.letter-spacing-0,
.letter-spacing-0 {
  letter-spacing: normal !important;
}
.textopacity.line-height-0 {
  line-height: normal !important;
}
.text-black {
  color: #212121 !important;
}
.text-secondary-666666 {
  color: #666;
}
#root {
  height: 100%;
  position: relative;
  z-index: 1;
}
#root > .container {
  min-height: 100vh !important;
  padding-bottom: 100px;
}
.text-secondary {
  color: rgba(33, 33, 33, 0.6) !important;
}
.questionCount.text-secondary {
  color: #a2a2a2 !important;
}
.text-666 {
  color: #666666 !important;
}
.lh-38 {
  line-height: 38px !important;
}
.lh-18 {
  line-height: 18px !important;
}
.lh-30 {
  line-height: 30px;
}
.lh-22 {
  line-height: 22px;
}
.lh-20 {
  line-height: 20px;
}
input[type="range"]::-webkit-slider-thumb {
  z-index: 10;
  position: relative;
  -webkit-appearance: none;
  border: 4px solid #fff;
  height: 32px;
  width: 32px;
  border-radius: 3px;
  background: #fd8f55;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* Add cool effects to your sliders! */
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  border: 4px solid #fff;
  height: 32px;
  width: 32px;
  border-radius: 3px;
  background: #fd8f55;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* Add cool effects to your sliders! */
}

.mt-6 {
  margin-top: 4rem;
}
.mb-6 {
  margin-bottom: 4rem;
}
.form-check-label {
  line-height: 18px;
  font-size: 12px;
  color: #a2a2a2;
  margin-top: 1px;
}

.text-a2a2a2 {
  color: #a2a2a2;
}
.form-control {
  height: 54px;
  color: #666666;
  font-size: 16px;
  border-color: #d9d9d9;
}
.form-group + div .btn-lg,
.form-group + .btn {
  height: 58px;
}
.form-control[readonly] {
  background-color: #f6f7f8;
  border: none !important;
}
.progress {
  height: 0.75rem !important;
  border-radius: 0px !important;
  background: #fbf9fd !important;
}
.theme-body-width {
  max-width: 1440px;
}

#tooltip-top {
  inset: auto auto 15px 0px !important;
}
