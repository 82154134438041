/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 21, 2021 */

body {
  font-family: "euclid_circular_bmedium" !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
}
h1,
h2,
h1 strong,
button,
button span,
p strong,
.card-title strong,
h4 strong,
h3 strong {
  font-family: "euclid_circular_bsemibold" !important;
  font-weight: normal !important;
}
b,
strong {
  font-weight: normal !important;
}
.fR,
a {
  font-family: "euclid_circular_bregular" !important;
  font-weight: normal !important;
}
.fM,
button small,
.subheading {
  font-family: "euclid_circular_bmedium" !important;
  font-weight: normal !important;
}
.fSb,
button {
  font-family: "euclid_circular_bsemibold" !important;
  font-weight: normal !important;
}

.card-text {
  font-family: "euclid_circular_bregular" !important;
}
.lh-22 {
  line-height: 22px;
}
.fs-14,
a,
button small {
  font-size: 0.875rem !important;
}
.fs-16,
button,
button span {
  font-size: 1rem !important;
}
.fs-18 {
  font-size: 1.125rem !important;
}
.fs-22,
.subheading {
  font-size: 1.375rem !important;
}
.fs-24 {
  font-size: 1.5rem !important;
}
.fs-28 {
  font-size: 1.75rem !important;
}
.fs-40 {
  font-size: 2.5rem !important;
}
.fs-48 {
  font-size: 3rem !important;
}
a {
  font-size: 0.875rem !important;
  font-family: "euclid_circular_bregular" !important;
}

@font-face {
  font-family: "euclid_circular_bbold";
  src: url("euclidcircularb-bold-webfont.woff2") format("woff2"),
    url("euclidcircularb-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@media (max-width: 768px) {
  .fs-40,
  .fs-48,
  h2,
  h1 {
    font-size: 1.75rem !important;
  }
}

@font-face {
  font-family: "euclid_circular_bbold_italic";
  src: url("euclidcircularb-bolditalic-webfont.woff2") format("woff2"),
    url("euclidcircularb-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_blight";
  src: url("euclidcircularb-light-webfont.woff2") format("woff2"),
    url("euclidcircularb-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_blight_italic";
  src: url("euclidcircularb-lightitalic-webfont.woff2") format("woff2"),
    url("euclidcircularb-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_bmedium";
  src: url("euclidcircularb-medium-webfont.woff2") format("woff2"),
    url("euclidcircularb-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_bMdIt";
  src: url("euclidcircularb-mediumitalic-webfont.woff2") format("woff2"),
    url("euclidcircularb-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_bregular";
  src: url("euclidcircularb-regular-webfont.woff2") format("woff2"),
    url("euclidcircularb-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_bRgIt";
  src: url("euclidcircularb-regularitalic-webfont.woff2") format("woff2"),
    url("euclidcircularb-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_bsemibold";
  src: url("euclidcircularb-semibold-webfont.woff2") format("woff2"),
    url("euclidcircularb-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclid_circular_bSBdIt";
  src: url("euclidcircularb-semibolditalic-webfont.woff2") format("woff2"),
    url("euclidcircularb-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
